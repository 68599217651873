<template>
  <main-template :header="header" :is-loading="isLoading">
    <div class="container-fluid container">
      <section id="property-release" class="row" style="margin-bottom: 20px; padding: 20px 10px; background-color: #fafafa">
        <div id="buy-release" class="col-xl-6" style="padding: 0">
          <h2>Buy</h2>
          <small>New Release</small>
          <div class="property-release__container list-horizontal" id="buy_items">
            <div>
              <ul>
                <li v-for="(propertyBuy, index) of propertyBuyList" :key="`buy-${index}`">
                  <img class="cover" alt="" style="width: 100%; height: 189px; cursor: pointer; border-radius: 8px 8px 0 0" @click="viewPropertyDetail(propertyBuy)"
                       :src="getImageUrl(propertyBuy.photoList)">
                  <div class="short_content">
                    <div v-if="propertyBuy.status !== PROPERTY_STATUS.NO_STATUS"
                         class="building-type" :class="`building-type-${propertyBuy.status}`">{{PROPERTY_STATUS_TEXT[propertyBuy.status]}}</div>
                    <div class="marketing" v-if="propertyBuy.marketingWord !== ''">{{propertyBuy.marketingWord}}</div>
                  </div>
                  <div style="padding: 10px">
                    <div class="name" style="cursor: pointer" @click="viewPropertyDetail(propertyBuy)">{{propertyBuy.name}}</div>
                    <div class="location">
                      <img src="@/assets/icons/icon_location.svg">{{getLocationTitle(propertyBuy.location)}}
                    </div>
                    <div class="price" style="margin: 10px 0">
                      <span class="currency">THB </span>
                      <span>{{numberFormat(propertyBuy.salePrice)}}</span>
                    </div>
                    <div class="overview">
                      <div class="property-type">
                        <img :src='"@/assets/icons/"+getPropertyTypeIcon(propertyBuy.type)+""'
                             style="height: 18px; margin-right: 4px; opacity: .8">
                        <div>{{ PROPERTY_TYPE_TITLE[propertyBuy.type] || 'N/A' }}</div>
                      </div>
                      <div class="attr-icon-list">
                        <div class="attr-icon" v-if="propertyBuy.sleep !== ''">
                          <img alt="attr-icon" src="@/assets/icons/bedrooms.svg">
                          <div>{{propertyBuy.sleep}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyBuy.bath !== ''">
                          <img alt="attr-icon" src="@/assets/icons/bathrooms.svg">
                          <div>{{propertyBuy.bath}}</div>
                        </div>
                        <div v-if="(propertyBuy.type === PROPERTY_TYPE.VILLAS_AND_HOUSES || propertyBuy.type === PROPERTY_TYPE.LAND) && propertyBuy.landArea !== ''"
                             class="attr-icon">
                          <img alt="attr-icon" src="@/assets/icons/area.svg">
                          <div>{{propertyBuy.landArea}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyBuy.type === PROPERTY_TYPE.APARTMENTS_AND_CONDOS && propertyBuy.livingArea !== ''">
                          <img alt="attr-icon" src="@/assets/icons/area.svg">
                          <div>{{propertyBuy.livingArea}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyBuy.type === PROPERTY_TYPE.COMMERCIAL && propertyBuy.buildingArea !== ''">
                          <img alt="attr-icon" src="@/assets/icons/area.svg">
                          <div>{{propertyBuy.buildingArea}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyBuy.parking !== ''">
                          <img alt="attr-icon" src="@/assets/icons/garage.svg">
                          <div>{{propertyBuy.parking}}</div>
                        </div>
                      </div>
                    </div>
                    <div style="text-align: right">
                      <a @click="viewPropertyDetail(propertyBuy)" class="btn__more-detail">More detail</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <a class="btn btn--show-all" style="cursor: pointer" @click="gotoPage('buy')">Show all property for sale</a>
        </div>
        <div id="rental-release" class="col-xl-6" style="padding: 0">
          <h2>Rent</h2>
          <small>Special Deals</small>
          <div class="property-release__container list-horizontal" id="rental_items">
            <div>
              <ul>
                <li v-for="(propertyRent, index) of propertyRentList" :key="`rent-${index}`">
                  <img class="cover" alt="" style="width: 100%; height: 189px; cursor: pointer; border-radius: 8px 8px 0 0" @click="viewPropertyDetail(propertyRent)"
                       :src="getImageUrl(propertyRent.photoList)">
                  <div class="short_content">
                    <div v-if="propertyRent.status !== PROPERTY_STATUS.NO_STATUS"
                         class="building-type" :class="`building-type-${propertyRent.status}`">{{PROPERTY_STATUS_TEXT[propertyRent.status]}}</div>
                    <div class="marketing" v-if="propertyRent.marketingWord !== ''">{{propertyRent.marketingWord}}</div>
                  </div>
                  <div style="padding: 10px">
                    <div class="name" style="cursor: pointer" @click="viewPropertyDetail(propertyRent)">{{propertyRent.name}}</div>
                    <div class="location">
                      <img src="@/assets/icons/icon_location.svg">{{getLocationTitle(propertyRent.location)}}
                    </div>
                    <div class="price" style="margin: 10px 0">
                      <span class="currency">THB </span>
                      <span>{{numberFormat(propertyRent.rentPrice)}}</span>
                      <span> /{{RENT_TYPE_TITLE[propertyRent.rentType] || ''}}</span>
                    </div>
                    <div class="overview">
                      <div class="property-type">
                        <img :src='"@/assets/icons/"+getPropertyTypeIcon(propertyRent.type)+""'
                             style="height: 18px; margin-right: 4px; opacity: .8">
                        <div>{{ PROPERTY_TYPE_TITLE[propertyRent.type] || 'N/A' }}</div>
                      </div>
                      <div class="attr-icon-list">
                        <div class="attr-icon" v-if="propertyRent.sleep !== ''">
                          <img alt="attr-icon" src="@/assets/icons/bedrooms.svg">
                          <div>{{propertyRent.sleep}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyRent.bath !== ''">
                          <img alt="attr-icon" src="@/assets/icons/bathrooms.svg">
                          <div>{{propertyRent.bath}}</div>
                        </div>
                        <div v-if="(propertyRent.type === PROPERTY_TYPE.VILLAS_AND_HOUSES || propertyRent.type === PROPERTY_TYPE.LAND) && propertyRent.landArea !== ''"
                             class="attr-icon">
                          <img alt="attr-icon" src="@/assets/icons/area.svg">
                          <div>{{propertyRent.landArea}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyRent.type === PROPERTY_TYPE.APARTMENTS_AND_CONDOS && propertyRent.livingArea !== ''">
                          <img alt="attr-icon" src="@/assets/icons/area.svg">
                          <div>{{propertyRent.livingArea}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyRent.type === PROPERTY_TYPE.COMMERCIAL && propertyRent.buildingArea !== ''">
                          <img alt="attr-icon" src="@/assets/icons/area.svg">
                          <div>{{propertyRent.buildingArea}}</div>
                        </div>
                        <div class="attr-icon" v-if="propertyRent.parking !== ''">
                          <img alt="attr-icon" src="@/assets/icons/garage.svg">
                          <div>{{propertyRent.parking}}</div>
                        </div>
                      </div>
                    </div>
                    <div style="text-align: right">
                      <a @click="viewPropertyDetail(propertyRent)" class="btn__more-detail">More detail</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <a class="btn btn--show-all" style="cursor: pointer" @click="gotoPage('rental')">Show all property for rent</a>
        </div>
      </section>

      <section id="why-property" class="row">
        <div class="row">
          <div class="col-lg-12 align-self-lg-center" v-html="homeDescription">
          </div>
          <!--<div class="col-lg-6">-->
            <!--<h4>Why Phuket Villa and Home?</h4>-->
            <!--<p>Modern Property is a professional asset management company that will handle your property whether it is for rental or sale.</p>-->
          <!--</div>-->
        </div>
      </section>

      <section id="services" class="property-management">
        <h2>Property Management Service</h2>
        <div class="row property-management__service">
          <div class="col-sm-4">
            <div class="property-management__service--item">
              <img src="@/assets/icons/icon_legal-consultant.svg" height="40" alt="Legal Consultant">
              <div>
                <h3>Legal Consultant</h3>
                <div v-html="legalConsultant" class="property-service-content"></div>
                <a class="btn-link" @click="gotoPage('legalConsultant')">More Detail</a>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="property-management__service--item">
              <img src="@/assets/icons/icon_villa-management.svg" height="40" alt="Villa Management">
              <div>
                <h3>Villa Management</h3>
                <div v-html="villaManagement" class="property-service-content"></div>
                <a class="btn-link" @click="gotoPage('villaManagement')">More Detail</a>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="property-management__service--item">
              <img src="@/assets/icons/icon_estate-management.svg" height="40" alt="Estate Management">
              <div>
                <h3>Estate Management</h3>
                <div v-html="estateManagement" class="property-service-content"></div>
                <a  class="btn-link" @click="gotoPage('estateManagement')">More Detail</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main-template>
</template>

<script>
import HomeApi from '@/api/home-api'
import MainTemplate from '@/templates/MainTemplate'
import {
  LOCATION,
  LIST_TYPE,
  PROPERTY_TYPE,
  RENT_TYPE_TITLE,
  PROPERTY_STATUS,
  PROPERTY_STATUS_TEXT,
  PROPERTY_TYPE_TITLE,
  SERVICE_KEY
} from '@/configs/constants'
import { numberFormat, getImageUrl, delay } from '@/helpers'

export default {
  name: 'Home',
  components: { MainTemplate },
  data () {
    return {
      isLoading: false,
      getImageUrl,
      numberFormat,
      PROPERTY_TYPE,
      RENT_TYPE_TITLE,
      PROPERTY_STATUS,
      PROPERTY_STATUS_TEXT,
      PROPERTY_TYPE_TITLE,
      propertyBuyList: [],
      propertyRentList: [],
      legalConsultant: '',
      villaManagement: '',
      estateManagement: '',
      homeDescription: '',
      header: {
        className: '',
        style: '',
        imgPath: '',
        isShow: true
      }
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const home = await HomeApi.list()

        const header = {
          className: '',
          style: '',
          imgPath: home.coverImgUrl,
          isShow: true,
          resizeLogo: true
        }
        const propertyBuyList = home.buy
        const propertyRentList = home.rental
        const serviceList = home.serviceList

        const legalConsultantResult = serviceList.find(item => item.key === SERVICE_KEY.LEGAL_CONSULTANT)
        const villaManagementResult = serviceList.find(item => item.key === SERVICE_KEY.VILLA_MANAGEMENT)
        const estateManagementResult = serviceList.find(item => item.key === SERVICE_KEY.ESTATE_MANAGEMENT)
        const homeResult = serviceList.find(item => item.key === SERVICE_KEY.HOME)

        const legalConsultant = (legalConsultantResult !== undefined) ? legalConsultantResult.value : ''
        const villaManagement = (villaManagementResult !== undefined) ? villaManagementResult.value : ''
        const estateManagement = (estateManagementResult !== undefined) ? estateManagementResult.value : ''
        const homeDescription = (homeResult !== undefined) ? homeResult.value : ''

        prefetchItems = {
          header,
          propertyBuyList,
          propertyRentList,
          legalConsultant,
          villaManagement,
          estateManagement,
          homeDescription
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)

        this.$store.dispatch('clearPrefetchItems')
      }
    },
    getLocationTitle: function (locationId) {
      const result = LOCATION.find(item => item.id === locationId)

      if (result !== undefined) {
        return result.title
      }

      return 'N/A'
    },
    viewPropertyDetail: function (property) {
      return this.$router.push({
        name: 'PropertyDetail',
        params: {
          propertyId: property.id
        }
      })
    },
    gotoPage: function (page) {
      let routeName = 'Home'
      let routeParam

      switch (page) {
        case 'buy':
          routeName = 'AllPropertyListByType'
          routeParam = {
            listType: LIST_TYPE.BUY
          }
          break
        case 'rental':
          routeName = 'AllPropertyListByType'
          routeParam = {
            listType: LIST_TYPE.RENT
          }
          break
        case 'legalConsultant':
          routeName = 'LegalConsultant'
          break
        case 'villaManagement':
          routeName = 'VillaManagement'
          break
        case 'estateManagement':
          routeName = 'EstateManagement'
          break
      }

      return this.$router.push({
        name: routeName,
        params: routeParam
      })
    },
    getPropertyTypeIcon: function (type) {
      switch (type) {
        case PROPERTY_TYPE.VILLAS_AND_HOUSES:
          return 'icon_house.svg'
        case PROPERTY_TYPE.APARTMENTS_AND_CONDOS:
          return 'icon_condo.svg'
        case PROPERTY_TYPE.LAND:
          return 'icon_land.svg'
        case PROPERTY_TYPE.COMMERCIAL:
          return 'icon_house.svg'
        default:
          return 'icon_house.svg'
      }
    }
  }
}
</script>

<style scoped>
  .more-detail {
    position: relative;
    opacity: .35;
    text-decoration: underline;
    font-size:14px;
    cursor: pointer;
  }

  .btn-link {
    cursor: pointer;
  }

  .name:hover {
    text-decoration: underline;
  }

  .property-type {
    display: flex !important;
    align-self: center;
    color: #484848;
    font-weight: 500;
  }

  .attr-icon-list {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }

  .attr-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #484848;
    font-weight: 500;
  }

  .attr-icon img {
    height: 18px;
    margin-right: 5px;
    opacity: .8;
  }

  .property-service-content {
    height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px
  }
</style>

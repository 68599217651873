import Vue from 'vue'
import Router from 'vue-router'
import propertyRoute from '@/views/Property/router'
import Home from '@/views/Home.vue'
import PageNotFound from '@/views/PageNotFound'
import About from '@/views/About.vue'
import ContactUs from '@/views/ContactUs.vue'
import LegalConsultant from '@/views/ManagementService/LegalConsultant'
import VillaManagement from '@/views/ManagementService/VillaManagement'
import EstateManagement from '@/views/ManagementService/EstateManagement'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import TermOfUse from '@/views/TermOfUse'
import ListProperty from '@/views/ListProperty'

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/property',
        component: () => import(/* webpackChunkName: "property" */ '@/views/Property/Index.vue'),
        children: propertyRoute
      },
      {
        path: '/about-us',
        name: 'AboutUs',
        component: About
      },
      {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/legal-consultant',
        name: 'LegalConsultant',
        component: LegalConsultant
      },
      {
        path: '/villa-management',
        name: 'VillaManagement',
        component: VillaManagement
      },
      {
        path: '/estate-management',
        name: 'EstateManagement',
        component: EstateManagement
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
      },
      {
        path: '/term-of-use',
        name: 'TermOfUse',
        component: TermOfUse
      },
      {
        path: '/list-property',
        name: 'ListProperty',
        component: ListProperty
      },
      {
        path: '/page-not-found',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  })
}

<template>
  <div style="height: 100vh">
    <span style="padding: 10px">404 PAGE NOT FOUND!</span>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html, body {
  font-family: Inter, "Helvetica Neue", Georgia, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  line-height: 1.5;
  /*background-color: #484848;*/
  margin: 0;
  padding: 0;
}
</style>

import baseApi from './base-api'
import { PROPERTY_BOOKING_API_URL } from '../configs/service-url'

const list = async () => {
  try {
    const url = PROPERTY_BOOKING_API_URL.HOME
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

export default {
  list
}

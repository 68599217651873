const _getActiveMenu = (router) => {
  const currentRoute = router.currentRoute.path
  const routePathList = currentRoute.split('/')
  const activeMenuRoute = routePathList[1]
  const activeSubMenuRoute = routePathList[2]

  if (activeMenuRoute !== 'property') {
    return activeMenuRoute
  }

  return activeSubMenuRoute
}

export const isActiveMenu = (router, menuName = '') => {
  const activeMenu = _getActiveMenu(router)

  return (activeMenu === menuName)
}

import Swal from 'sweetalert2'

const alertMessageError = function (error) {
  Swal.fire({
    type: 'error',
    html: `<b>${error}</b>`,
    confirmButtonText: 'Close'
  })
}

const alertMessageSuccess = function (success) {
  Swal.fire({
    type: 'success',
    html: `<b>${success}</b>`,
    confirmButtonText: 'Close'
  })
}

const showConfirmDelete = async function (confirmText, confirmButtonText, cancelButtonText) {
  const result = await Swal.fire({
    text: confirmText,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    confirmButtonColor: 'red',
    cancelButtonText: cancelButtonText
  })

  return result
}

export default {
  methods: {
    alertMessageSuccess,
    alertMessageError,
    showConfirmDelete
  }
}

import Vue from 'vue'

const KEY_ACCESS_TOKEN = 'access-token'

const Cookies = {}

const setAccessToken = (token) => {
  Vue.$cookies.set(KEY_ACCESS_TOKEN, token)
}

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    return Vue.$cookies.get(KEY_ACCESS_TOKEN)
  }

  return Cookies[KEY_ACCESS_TOKEN] || ''
}

const clear = () => {
  localStorage.clear()
}

export default {
  setAccessToken,
  getAccessToken,
  clear,
  Cookies
}

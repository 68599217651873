const API_PROPERTY_BASE_URL = process.env.VUE_APP_API_PROPERTY_BASE_URL

const PROPERTY_BOOKING_URL = {
  HOME: '/public/home',
  PROPERTY: '/public/property',
  SEND_EMAIL: '/public/send-email',
  CONFIG: '/public/config'
}

const buildServiceUrl = (baseUrl, serviceList) => {
  let result = {}
  Object.keys(serviceList).map(function (key, index) {
    result[key] = baseUrl + serviceList[key]
  })

  return result
}

export const PROPERTY_BOOKING_API_URL = buildServiceUrl(API_PROPERTY_BASE_URL, PROPERTY_BOOKING_URL)

<template>
  <div>
    <div v-if="loading" class="loading">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
    <header-template :header="header"></header-template>
    <slot></slot>
    <FooterTemplate :footer="footer"></FooterTemplate>
  </div>
</template>

<script>
import HeaderTemplate from '@/templates/HeaderTemplate'
import FooterTemplate from '@/templates/FooterTemplate'

export default {
  name: 'MainTemplate',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    header: {
      type: Object,
      default: () => {
        return {
          className: '',
          style: '',
          imgPath: '',
          isShow: true
        }
      }
    },
    footer: {
      type: Object,
      default: () => {
        return {
          className: ''
        }
      }
    }
  },
  components: {
    HeaderTemplate,
    FooterTemplate
  },
  computed: {
    loading () {
      return this.isLoading
    }
  }
}
</script>
<style scoped>

</style>

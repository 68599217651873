<template>
  <div :class="footer.className">
    <footer class="container-fluid">
      <div class="row">
        <div class="col-md-6 d-md-block d-none">
          <ul id="menu_footer">
            <li :class="{'active': isActiveMenu($router)}">
              <a @click="onMenuClick('about')">About Us</a>
            </li>
            <li :class="{'active': isActiveMenu($router, 'buy')}">
              <a @click="onMenuClick('buy')">Buy</a>
            </li>
            <li :class="{'active': isActiveMenu($router, 'rent')}">
              <a>Rent</a>
            </li>
            <li :class="{'active': isActiveMenu($router, 'list-property')}">
              <a>List Property</a>
            </li>
            <li :class="{'active': isActiveMenu($router, 'contact-us')}">
              <a @click="onMenuClick('contactUs')">Contact Us</a>
            </li>
          </ul>
          <ul id="terms_policy">
            <li :class="{'active': isActiveMenu($router, 'privacy-policy')}">
              <a @click="onMenuClick('privacyPolicy')">Privacy Policy</a>
            </li>
            <li :class="{'active': isActiveMenu($router, 'term-of-use')}">
              <a @click="onMenuClick('termOfUse')">Terms of Use</a>
            </li>
          </ul>
        </div>
        <div id="property_contact" class="col-md-6">
          <img :src="webLogoUrl" style="height: 75px">
          <section>
            <a class="link--email" :href="`mailto:${contactEmail}`">{{contactEmail}}</a>
            <br>
            <div>phone:
              <a v-for="(phone, index) of companyContact.phone" :key="`phone-${index}`"
                 class="link--tel"
                 :href="`tel:${phone}`">{{phone}}<span v-if="index < companyContact.phone.length - 1">, </span></a>
            </div>
            <div>mobile:
              <a v-for="(mobile, index) of companyContact.mobile" :key="`mobile-${index}`"
                 class="link--tel"
                 :href="`tel:${mobile}`">{{mobile}}<span v-if="index < companyContact.mobile.length - 1">, </span></a>
            </div>
            <div>fax:
              <a v-for="(fax, index) of companyContact.fax" :key="`fax-${index}`"
                 class="link--tel"
                 :href="`tel:${fax}`">{{fax}}<span v-if="index < companyContact.fax.length - 1">, </span></a>
            </div>
            <address>{{companyLocation.address1}}</address>
            <address>{{companyLocation.address2}}</address>
          </section>
          <section class="social">
            <a v-if="socialMedia.facebook !== ''" :href="socialMedia.facebook" target="_blank" class="link_icon"><img src="@/assets/icons/icon_facebook.svg" width="24" height="24"></a>
            <a v-if="socialMedia.instagram !== ''" :href="socialMedia.instagram" target="_blank" class="link_icon"><img src="@/assets/icons/icon_instagram.svg" width="24" height="24"></a>
            <a v-if="socialMedia.twitter !== ''" :href="socialMedia.twitter" target="_blank" class="link_icon"><img src="@/assets/icons/icon_twitter.svg" width="24" height="24"></a>
            <a v-if="socialMedia.pinterest !== ''" :href="socialMedia.pinterest" target="_blank" class="link_icon"><img src="@/assets/icons/icon_pinterest.svg" width="24" height="24"></a>
          </section>
        </div>
      </div>
    </footer>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-7">
          Copyright © 2019 {{webTitle}} All Rights Reserved.
        </div>
        <div id="property_credit" class="col-md-5">
          Website Design by <a href="https://www.hoteliers.guru" target="_blank">Hoteliers Dot Guru</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import { delay } from '@/helpers'

import { LIST_TYPE } from '@/configs/constants'
import { isActiveMenu } from '@/helpers/router'

import ConfigApi from '@/api/config-api'

export default {
  name: 'FooterTemplate',
  props: {
    footer: {
      type: Object,
      default: () => {
        return {
          className: ''
        }
      }
    }
  },
  data () {
    return {
      isActiveMenu,
      webTitle: '',
      webLogoUrl: '',
      contactEmail: '',
      companyLocation: {
        latitude: '',
        longitude: '',
        address1: '',
        address2: ''
      },
      socialMedia: {
        facebook: '',
        instagram: '',
        twitter: '',
        pinterest: ''
      },
      companyContact: {
        phone: [''],
        mobile: [''],
        fax: ['']
      }
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const footerData = await ConfigApi.footerData()

        const socialMedia = footerData.socialMedia
        const webTitle = footerData.header.webTitle
        const webLogoUrl = footerData.header.webLogoUrl
        const companyLocation = footerData.companyLocation
        const companyContact = footerData.companyContact
        const contactEmail = get(footerData, 'systemEmail.to[0]', '')

        prefetchItems = {
          socialMedia,
          webTitle,
          webLogoUrl,
          companyLocation,
          companyContact,
          contactEmail
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)
        this.$store.dispatch('clearPrefetchItems')
      }
    },
    onMenuClick: function (menu) {
      let routeName = 'Home'
      let routeParam

      switch (menu) {
        case 'home':
          routeName = 'Home'
          break
        case 'buy':
          routeName = 'AllPropertyListByType'
          routeParam = {
            listType: LIST_TYPE.BUY
          }
          break
        case 'contactUs':
          routeName = 'ContactUs'
          break
        case 'about':
          routeName = 'AboutUs'
          break
        case 'privacyPolicy':
          routeName = 'PrivacyPolicy'
          break
        case 'termOfUse':
          routeName = 'TermOfUse'
          break
      }

      return this.$router.push({
        name: routeName,
        params: routeParam
      })
    }
  }
}
</script>

<style scoped>
  #menu_footer a,
  #terms_policy a{
    cursor: pointer;
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export function createStore () {
  return new Vuex.Store({
    // IMPORTANT: state must be a function so the module can be
    // instantiated multiple times
    state: () => ({
      prefetchItems: null
    }),

    actions: {
      initPrefetchItems (context, items) {
        context.commit('setPrefetchItems', items)
      },
      clearPrefetchItems (context) {
        context.commit('setPrefetchItems', null)
      }
    },

    mutations: {
      setPrefetchItems (state, items) {
        state.prefetchItems = items
      }
    }
  })
}

<template>
  <div>
    <div class="search-box" :class="{'show-search-box': isShowSearchBox}">
      <div class="close-search-box-mobile">
        <img alt="close" @click="onCloseSearchBoxButtonClick" src="@/assets/icons/icon_close.svg" style="height: 20px">
      </div>
      <div class="search_type">
        <input type="radio" id="search_type-buy" name="check_search_type" :value="LIST_TYPE.BUY" v-model="listType" checked><label for="search_type-buy">Buy</label>
        <input type="radio" id="search_type-rental" name="check_search_type" :value="LIST_TYPE.RENT" v-model="listType"><label for="search_type-rental">Rent</label>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <select-property-type-dropdown-component v-model="selectPropertyType"/>
        </div>
        <div class="col-lg-5">
          <select-location-dropdown-component v-model="selectedLocation"/>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-search" style="width: 100%" @click="onSearchProperty">Search</button>
        </div>
      </div>
    </div>
    <!--<div style="position: fixed; top:0; left:0; background: rgba(255,255,255,.98); z-index:1000; width:100vw; height:100vh; display: none;">-->
      <!--<div>-->
        <!--<strong>Listing Type</strong>-->
        <!--<div>-->
          <!--<label><input type="radio" name="listing-type" checked> Buy </label>-->
          <!--<label><input type="radio" name="listing-type"> Rental </label>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div>-->
        <!--<strong>Property Type</strong>-->
        <!--<div>-->
          <!--<label><input type="checkbox" name="listing-type" checked> All Property</label>-->
          <!--<label><input type="checkbox" name="listing-type">Houses & Villas</label>-->
          <!--<label><input type="checkbox" name="listing-type">Condos & Apartments</label>-->
          <!--<label><input type="checkbox" name="listing-type">Lands</label>-->
          <!--<label><input type="checkbox" name="listing-type">Commercials</label>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div>-->
        <!--<strong>Locations</strong>-->
        <!--<ul>-->
          <!--<li>All</li>-->
          <!--<li>West</li>-->
          <!--<li>North</li>-->
          <!--<li>South</li>-->
          <!--<li>East</li>-->
          <!--<li>Town</li>-->
        <!--</ul>-->
        <!--<div style="font-size: .875rem">-->
          <!--<div>-->
            <!--<h3>West</h3>-->
            <!--<ul style="list-style: none">-->
              <!--<li><label><input type="checkbox" class="all-location" data-zone="west"> All</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="west" value="kamala"> Kamala</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="west" value="patong"> Patong</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="west" value="surin"> Surin</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="west" value="karon"> Karon</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="west" value="kata"> Kata</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="west" value="kalim"> Kalim</label></li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div>-->
            <!--<h3>North</h3>-->
            <!--<ul style="list-style: none">-->
              <!--<li><label><input type="checkbox" class="all-location" data-zone="north"> All</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="cherng-talay"> Cherng Talay</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="layan"> Layan</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="bangtao"> Bangtao</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="laguna"> Laguna</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="natai"> Natai</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="nai-yang"> Nai Yang</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="thai-muang"> Thai Muang</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="mai-khao"> Mai Khao</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="nai-thon"> Nai Thon</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="north" value="thalang"> Thalang</label></li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div>-->
            <!--<h3>South</h3>-->
            <!--<ul style="list-style: none">-->
              <!--<li><label><input type="checkbox" class="all-location" data-zone="south"> All</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="south" value="rawai"> Rawai</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="south" value="nai-harn"> Nai Harn</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="south" value="chalong"> Chalong</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="south" value="cape-panwa"> Cape Panwa</label></li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div>-->
            <!--<h3>East</h3>-->
            <!--<ul style="list-style: none">-->
              <!--<li><label><input type="checkbox" class="all-location" data-zone="east"> All</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="east" value="paklok"> Paklok</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="east" value="koh-kaew"> Koh Kaew</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="east" value="cape-yamu"> Cape Yamu</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="east" value="ao-por"> Ao Por</label></li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div>-->
            <!--<h3>Town</h3>-->
            <!--<ul style="list-style: none">-->
              <!--<li><label><input type="checkbox" class="all-location" data-zone="town"> All</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="town" value="kathu"> Kathu</label></li>-->
              <!--<li><label><input type="checkbox" class="location" data-zone="town" value="phuket-town"> Phuket Town</label></li>-->
            <!--</ul>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<button type="button" style="position: absolute; left:0; bottom:0; width:100%" @click="onSearchProperty">Search</button>-->
    <!--</div>-->
  </div>
</template>

<script>
import SelectPropertyTypeDropdownComponent from '@/components/SelectPropertyTypeDropdownComponent'
import SelectLocationDropdownComponent from '@/components/SelectLocationDropdownComponent'
import { LIST_TYPE, PROPERTY_TYPE, LOCATION } from '@/configs/constants'

export default {
  name: 'SearchBoxComponent',
  components: {
    SelectPropertyTypeDropdownComponent,
    SelectLocationDropdownComponent
  },
  props: {
    isOpenSearchBox: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isOpenSearchBox: function (newVal) {
      this.isShowSearchBox = newVal
    }
  },
  data () {
    return {
      LOCATION,
      LIST_TYPE,
      PROPERTY_TYPE,
      listType: LIST_TYPE.BUY,
      isShowSearchBox: false,
      selectPropertyType: {
        all: false,
        villa: false,
        condo: false,
        land: false,
        commercial: false
      },
      selectedLocation: {
        areaList: [],
        locationList: []
      },
      isSearchBtnClick: false
    }
  },
  methods: {
    onSearchProperty: function () {
      const selectPropertyTypeList = Object.keys(this.selectPropertyType).filter(key => {
        return (this.selectPropertyType[key] === true) && (key !== 'all')
      })

      let routeName = 'searchProperty'
      let routeParam = {
        listType: LIST_TYPE.BUY,
        selectPropertyTypeList: selectPropertyTypeList,
        selectedLocation: this.selectedLocation
      }

      switch (this.listType) {
        case 'buy':
          routeParam.listType = LIST_TYPE.BUY
          break
        case 'rent':
          routeParam.listType = LIST_TYPE.RENT
          break
      }

      return this.$router.push({
        name: routeName,
        params: routeParam,
        query: {
          page: 1,
          t: Date.now()
        }
      })
    },
    onCloseSearchBoxButtonClick: function () {
      this.isShowSearchBox = false
      this.$emit('onCloseSearchBox')
    }
  }
}
</script>

<style scoped>
  .show-search-box {
    display: block !important;
  }
  .close-search-box-mobile {
    display: none;
    text-align: right;
    margin-bottom: 15px;
  }
</style>

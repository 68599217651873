<template>
  <PuSkeleton :loading="isLoading">
    <header :class="header.className" :style="header.style">
      <nav>
        <div class="container-fluid d-flex nav-header" style="padding: 10px 25px;">
          <div class="ml-auto">
            <ul class="nav_menu">
              <li :class="{'active': isActiveMenu($router)}">
                <a @click="onMenuClick('home')">Home</a>
              </li>
              <li :class="{'active': isActiveMenu($router, 'about-us')}">
                <a @click="onMenuClick('about')">About us</a>
              </li>
              <li class="has-sub_menu" :class="{'active': isActiveMenu($router, 'buy')}">
                <a @click="onMenuClick('buy')">Buy</a>
                <ul class="sub_menu">
                  <li class="">
                    <a @click="onMenuClick('buy')">
                      All Property for Sale
                    </a>
                  </li>
                  <li class="">
                    <a @click="onMenuClick('buyVilla')">
                      Villas for Sale
                    </a>
                  </li>
                  <li class="">
                    <a @click="onMenuClick('buyCondo')">
                      Condos for Sale
                    </a>
                  </li>
                  <li class="">
                    <a @click="onMenuClick('buyLand')">
                      Land for Sale
                    </a>
                  </li>
                  <!--<li class="">-->
                    <!--<a>-->
                      <!--Property Map-->
                    <!--</a>-->
                  <!--</li>-->
                </ul>
              </li>
              <li class="has-sub_menu" :class="{'active': isActiveMenu($router, 'rent')}">
                <a @click="onMenuClick('rental')">Rent</a>
                <ul class="sub_menu">
                  <li class="" @click="onMenuClick('rental')">
                    <a>All Property for Rent</a>
                  </li>
                  <li class="" @click="onMenuClick('rentalVilla')">
                    <a>Villas for Rent</a>
                  </li>
                  <li class="" @click="onMenuClick('rentalCondo')">
                    <a>Condos for Rent</a>
                  </li>
                  <li class="" @click="onMenuClick('rentalLand')">
                    <a>Land for Rent</a>
                  </li>
                  <!--<li class="">-->
                    <!--<a>Property Map</a>-->
                  <!--</li>-->
                </ul>
              </li>
              <li :class="{'active': isActiveMenu($router, 'list-property')}" @click="onMenuClick('listProperty')">
                <a>List Property</a>
              </li>
              <li :class="{'active': isActiveMenu($router, 'contact-us')}">
                <a @click="onMenuClick('contact')">Contact us</a>
              </li>
            </ul>
            <button type="button" class="btn_icon d-lg-none">
              <img src="@/assets/icons/icon_search.svg" height="24" @click="onOpenSearchButtonClick">
            </button>
            <button type="button" class="btn_icon d-lg-none" @click="toggleMenu()">
              <div class="icon_menu"></div>
            </button>
          </div>
          <a class="d-flex logo-container" @click="onMenuClick('home')" style="position: absolute; background-color: #ffffff;">
            <img v-if="headerConfig.webLogoUrl !== ''" id="logo-header" :src="headerConfig.webLogoUrl" height="40">
          </a>
        </div>
      </nav>
      <div id="search-box-component">
        <search-box-component
          :is-open-search-box="isOpenSearchBox"
          @onCloseSearchBox="onCloseSearchBox"
        />
      </div>
      <img v-if="header.imgPath !== '' && header.isShow === true" alt="" :src="header.imgPath" width="100%" height="100%" style="object-fit: cover">
    </header>
  </PuSkeleton>
</template>

<script>
import { delay } from '@/helpers'
import { isActiveMenu } from '@/helpers/router'
import { LIST_TYPE, PROPERTY_TYPE } from '@/configs/constants'
import ConfigApi from '@/api/config-api'

import SearchBoxComponent from '@/components/SearchBoxComponent'

export default {
  name: 'HeaderTemplate',
  components: {
    SearchBoxComponent
  },
  props: {
    header: {
      type: Object,
      default: () => {
        return {
          className: '',
          style: '',
          imgPath: '',
          isShow: true,
          resizeLogo: true
        }
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isOpenSearchBox: false,
      headerConfig: {
        webLogoUrl: '',
        faviconUrl: '',
        webTitle: ''
      },
      isActiveMenu
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 200 || this.header.resizeLogo === false) {
      document.querySelector('body').classList.add('logo-normal')
    } else {
      document.querySelector('body').classList.remove('logo-normal')
    }

    window.addEventListener('scroll', this.handleScroll)

    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const headerConfig = await ConfigApi.headerData()

        const favicon = document.getElementById('favicon')
        favicon.href = headerConfig.faviconUrl

        const webTitle = document.getElementById('web-title')
        webTitle.innerText = headerConfig.webTitle

        prefetchItems = {
          headerConfig
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)

        this.$store.dispatch('clearPrefetchItems')
      }
    },
    onMenuClick: function (menu) {
      let routeName = 'Home'
      let routeParam

      switch (menu) {
        case 'home':
          routeName = 'Home'
          break
        case 'buy':
          routeName = 'AllPropertyListByType'
          routeParam = {
            listType: LIST_TYPE.BUY
          }
          break
        case 'buyVilla':
          routeName = 'PropertyListByType'
          routeParam = {
            listType: LIST_TYPE.BUY,
            propertyType: PROPERTY_TYPE.VILLAS_AND_HOUSES
          }
          break
        case 'buyCondo':
          routeName = 'PropertyListByType'
          routeParam = {
            listType: LIST_TYPE.BUY,
            propertyType: PROPERTY_TYPE.APARTMENTS_AND_CONDOS
          }
          break
        case 'buyLand':
          routeName = 'PropertyListByType'
          routeParam = {
            listType: LIST_TYPE.BUY,
            propertyType: PROPERTY_TYPE.LAND
          }
          break
        case 'buyMap':
          routeName = 'PropertyList'
          break
        case 'rental':
          routeName = 'AllPropertyListByType'
          routeParam = {
            listType: LIST_TYPE.RENT
          }
          break
        case 'rentalVilla':
          routeName = 'PropertyListByType'
          routeParam = {
            listType: LIST_TYPE.RENT,
            propertyType: PROPERTY_TYPE.VILLAS_AND_HOUSES
          }
          break
        case 'rentalCondo':
          routeName = 'PropertyListByType'
          routeParam = {
            listType: LIST_TYPE.RENT,
            propertyType: PROPERTY_TYPE.APARTMENTS_AND_CONDOS
          }
          break
        case 'rentalLand':
          routeName = 'PropertyListByType'
          routeParam = {
            listType: LIST_TYPE.RENT,
            propertyType: PROPERTY_TYPE.LAND
          }
          break
        case 'about':
          routeName = 'AboutUs'
          break
        case 'contact':
          routeName = 'ContactUs'
          break
        case 'listProperty':
          routeName = 'ListProperty'
          break
      }

      let elBody = document.querySelector('body')
      elBody.classList.remove('menu-open')

      return this.$router.push({
        name: routeName,
        params: routeParam
      })
    },
    getImgPath: function () {
      return `@/assets/${this.header.imgPath}`
    },
    toggleMenu: function () {
      let elBody = document.querySelector('body')

      if (elBody.classList.contains('menu-open')) {
        elBody.classList.remove('menu-open')
      } else {
        elBody.classList.add('menu-open')
      }
    },
    onOpenSearchButtonClick: function () {
      this.isOpenSearchBox = true
    },
    onCloseSearchBox: function () {
      this.isOpenSearchBox = false
    },
    handleScroll () {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 200 || this.header.resizeLogo === false) {
        document.querySelector('body').classList.add('logo-normal')
      } else {
        document.querySelector('body').classList.remove('logo-normal')
      }
    }
  }
}
</script>

<style scoped>
  .has-detail-menu #scroll-nav {
    display: block !important;
  }
  .hidden-search-box #search-box-component {
    display: none !important;
  }
</style>

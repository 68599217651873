<template>
  <main-template :header="header" :is-loading="isLoading">
    <section id="map" style="height: 100vh; position: absolute; top: 0; width: 100%;"
             v-if="companyLocation.latitude !== '' && companyLocation.longitude !== '' && Boolean(companyLocation.isShowMaps) === true">
      <div id="map-canvas" ref="mapCanvas" style="width: 100%; height: 100%;"></div>
    </section>
    <div class="container-fluid container" style="padding-top: 110px">
      <div class="row">
        <div class="col-lg-6" style="margin-bottom: 15px;">
          <h1 style="margin-bottom: .75em;">Contact us</h1>
          <div v-html="contactUs"></div>
        </div>
        <div class="col-lg-5 offset-lg-1">
          <div style="padding: 24px; background-color: #EEEEEE;">
            <h2 style="margin-bottom: .75em">Get in touch</h2>
            <div style="color: #888888; font-size: .875rem; font-weight: 500;">Please fill out the quick form and we
              will be in touch with the lightening speed.
            </div>
            <form style="margin-top: 1em">
              <div class="input__group">
                <label>First Name</label>
                <input type="text" v-model="contact.firstName">
              </div>
              <div class="input__group">
                <label>Last Name</label>
                <input type="text" v-model="contact.lastName">
              </div>
              <div class="input__group">
                <label>Email</label>
                <input type="email" v-model="contact.email">
              </div>
              <div class="input__group">
                <label>Country of Residence</label>
                <input type="email" v-model="contact.country">
              </div>
              <div class="input__group">
                <label>Phone</label>
                <input type="email" v-model="contact.phone">
              </div>
              <div class="input__group">
                <label>Message</label>
                <textarea v-model="contact.message"></textarea>
              </div>
              <div class="input__group" style="text-align: center">
                <vue-recaptcha :sitekey="RECAPTCHA_SITE_KEY"
                               @verify="verifyRecaptcha($event)"
                               @expired="recaptchaExpired"></vue-recaptcha>
              </div>
              <button type="button" class="btn"
                      :class="{'btn-disabled': !isVerifyRecaptcha}"
                      :disabled="!isVerifyRecaptcha"
                      @click="onSendEmailContact">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import { get } from 'lodash'
import { delay } from '@/helpers'

import GoogleMapsApiLoader from 'google-maps-api-loader'
import VueRecaptcha from 'vue-recaptcha'

import ConfigApi from '@/api/config-api'
import EmailApi from '@/api/email-api'
import MainTemplate from '@/templates/MainTemplate'
import AlertMixin from '@/mixins/alert-mixin'

import { SERVICE_KEY, COVER_IMG_TYPE, API_KEY } from '@/configs/constants'

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''

export default {
  name: 'ContactUs',
  mixins: [AlertMixin],
  components: {
    MainTemplate,
    VueRecaptcha
  },
  data () {
    return {
      RECAPTCHA_SITE_KEY,
      isLoading: false,
      isVerifyRecaptcha: false,
      contact: {
        verifyRecaptcha: '',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        phone: '',
        message: ''
      },
      header: {
        className: '',
        style: 'height: 65vh;',
        imgPath: '',
        isShow: true
      },
      google: null,
      map: null,
      companyLocation: {
        latitude: '',
        longitude: '',
        address1: '',
        address2: '',
        isShowMaps: 0
      },
      contactUs: ''
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  async updated () {
    if (this.companyLocation.latitude !== '' && this.companyLocation.longitude !== '') {
      const googleMapApi = await GoogleMapsApiLoader({
        apiKey: API_KEY.GOOGLE_MAP
      })
      this.google = googleMapApi

      this.initMap()
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const promises = [
          ConfigApi.coverImgUrl(COVER_IMG_TYPE.CONTACT_US),
          ConfigApi.companyLocation(),
          ConfigApi.configByKey(SERVICE_KEY.CONTACT_US)
        ]

        const result = await Promise.all(promises)
        const coverImg = result[0]
        const companyConfig = result[1]
        const companyLocation = companyConfig.companyLocation

        const contactUs = get(result[2], 'configValue', '')

        const header = {
          className: '',
          style: 'height: 100vh;',
          imgPath: coverImg.url,
          isShow: true,
          resizeLogo: true
        }
        prefetchItems = {
          header,
          companyLocation,
          contactUs
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)
        this.$store.dispatch('clearPrefetchItems')
      }
    },
    initMap: function () {
      const mapContainer = this.$refs.mapCanvas
      this.map = new this.google.maps.Map(
        mapContainer, {
          center: {
            lat: parseFloat(this.companyLocation.latitude),
            lng: parseFloat(this.companyLocation.longitude)
          },
          zoom: 17
        })
    },
    verifyRecaptcha: function (response) {
      this.contact.verifyRecaptcha = response
      this.isVerifyRecaptcha = true
    },
    recaptchaExpired: function () {
      this.isVerifyRecaptcha = false
    },
    onSendEmailContact: async function () {
      if (this._validateInquiryInput() === false) {
        return
      }

      try {
        this.isLoading = true

        await EmailApi.sendContact(this.contact)
      } catch (err) {
        // console.log(err)
      }

      this.contact = {
        verifyRecaptcha: '',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        phone: '',
        message: ''
      }

      this.isLoading = false
    },
    _validateInquiryInput: function () {
      if (this.contact.firstName === '') {
        this.alertMessageError('First name is require')
        return false
      }

      if (this.contact.lastName === '') {
        this.alertMessageError('Last name is require')
        return false
      }

      if (this.contact.email === '') {
        this.alertMessageError('email is require')
        return false
      }

      return true
    }
  }
}
</script>

<style scoped>
  .btn-disabled {
    opacity: 0.5;
  }
</style>

<template>
  <div class="search__option"
       :class="{'active': isActive}"
       v-click-outside-area="onClickOutsideDropDown">
    <input type="text" id="search_location"
           @click="searchLocationSelectOption"
           placeholder="Select Location"
           class="search__option--input"
           style="cursor: pointer;" readonly>
    <div style="font-size: .875rem">
      <div v-for="(location, tagIndex) of locationByAreaList"
           :key="tagIndex"
           style="display: inline-block; vertical-align: top; margin-right: 28px;">
        <h3>{{location.label}}</h3>
        <ul style="list-style: none">
          <li>
            <label>
              <input type="checkbox" class="all-location"
                     @click="onSelectAllLocation(tagIndex)"
                     v-model="location.isSelectedAll"> All
            </label>
          </li>
          <li v-for="(item, locationIndex) of location.list" :key="`location-${locationIndex}`">
            <label>
              <input type="checkbox" class="location"
                     @click="onSelectLocation(tagIndex, locationIndex)"
                     v-model="item.isSelected"> {{item.title}}
            </label>
          </li>
        </ul>
      </div>
    </div>
    <span style="display:block; position:absolute; top: 50%; right: 16px; transform: translateY(-50%); pointer-events:none; border-top:6px solid #cccccc; border-left:4px solid transparent; border-right:4px solid transparent"></span>
  </div>
</template>

<script>
import { LOCATION } from '@/configs/constants'
import { get, capitalize, uniq, xor } from 'lodash'
import { clickOutsideArea } from '@/directives/index'

const AREA_LIST = [
  'north',
  'west',
  'south',
  'east',
  'town'
]

export default {
  name: 'SelectLocationDropdownComponent',
  model: {
    prop: 'selectedLocation',
    event: 'onSelectLocationChange'
  },
  directives: {
    clickOutsideArea
  },
  props: {
    selectedLocation: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    async $route () {
      this.isActive = false
    }
  },
  data () {
    return {
      AREA_LIST,
      isActive: false,
      locationByAreaList: [],
      selectAreaList: [],
      selectLocationList: [],
      tmpSelectLocationList: []
    }
  },
  created: function () {
    this.locationByAreaList = this.getLocationByAreaList()
  },
  methods: {
    searchLocationSelectOption: function () {
      this.isActive = !this.isActive
    },
    getLocationByAreaList: function () {
      const locationByAreaList = AREA_LIST.map(tag => {
        const locationList = LOCATION.filter(location => {
          return location.tag.find(area => area === tag)
        })

        return {
          isSelectedAll: false,
          label: capitalize(tag),
          area: tag,
          list: locationList
        }
      })

      return locationByAreaList
    },
    onSelectLocation: function (tagIndex, locationIndex) {
      let locationByArea = this.locationByAreaList[tagIndex]
      let selectedLocation = locationByArea.list[locationIndex]

      selectedLocation.isSelected = !selectedLocation.isSelected
      locationByArea.isSelectedAll = true

      this.selectAreaList.push(locationByArea.area)

      const checkSelectedLocation = locationByArea.list.some(location => location.isSelected === false)
      if (checkSelectedLocation === true) {
        locationByArea.isSelectedAll = false
        this.selectAreaList = this.selectAreaList.filter(area => area !== locationByArea.area)
      }

      if (selectedLocation.isSelected === true) {
        this.tmpSelectLocationList.push(selectedLocation.id)
      } else {
        this.tmpSelectLocationList = this.tmpSelectLocationList.filter(item => item !== selectedLocation.id)
      }

      this.onSelectLocationDataChange()
    },
    onSelectAllLocation: function (tagIndex) {
      const locationByArea = this.locationByAreaList[tagIndex]
      const locationIdListByArea = locationByArea.list.map(location => { return location.id })

      locationByArea.isSelectedAll = !locationByArea.isSelectedAll
      locationByArea.list.forEach(item => { item.isSelected = locationByArea.isSelectedAll })

      if (locationByArea.isSelectedAll === true) {
        this.tmpSelectLocationList = uniq([...this.tmpSelectLocationList, ...locationIdListByArea])
        this.selectAreaList.push(locationByArea.area)
      } else {
        this.tmpSelectLocationList = xor(this.tmpSelectLocationList, locationIdListByArea)
        this.selectAreaList = this.selectAreaList.filter(area => area !== locationByArea.area)
      }

      this.onSelectLocationDataChange()
    },
    onSelectLocationDataChange: function () {
      this.selectLocationList = []
      this.tmpSelectLocationList.forEach(locationId => {
        const locationDetail = LOCATION.find(item => item.id === locationId)
        const area = get(locationDetail, 'tag[0]', '')
        const selectedArea = this.selectAreaList.find(item => item === area)

        if (selectedArea === undefined) {
          this.selectLocationList.push(locationId)
        }
      })

      const selectedLocation = {
        areaList: this.selectAreaList,
        locationList: this.selectLocationList
      }

      this.$emit('onSelectLocationChange', selectedLocation)
    },
    onClickOutsideDropDown: function () {
      this.isActive = false
    }
  }
}
</script>

<style scoped>

</style>

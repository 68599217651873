<template>
  <main-template :header="header" :is-loading="isLoading">
    <div class="container-fluid container">
      <h1>About us</h1>
      <div v-html="aboutUs"></div>
    </div>
  </main-template>
</template>

<script>
import { get } from 'lodash'
import { delay } from '@/helpers'

import ConfigApi from '@/api/config-api'
import MainTemplate from '@/templates/MainTemplate'
import { COVER_IMG_TYPE, SERVICE_KEY } from '@/configs/constants'

export default {
  name: 'About',
  components: { MainTemplate },
  data () {
    return {
      aboutUs: '',
      isLoading: false,
      header: {
        className: '',
        style: '',
        imgPath: '',
        isShow: true
      }
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const promises = [
          ConfigApi.coverImgUrl(COVER_IMG_TYPE.ABOUT_US),
          ConfigApi.configByKey(SERVICE_KEY.ABOUT_US)
        ]

        const result = await Promise.all(promises)

        const coverImg = result[0]
        const aboutUs = get(result[1], 'configValue', '')

        const header = {
          className: '',
          style: '',
          imgPath: coverImg.url,
          isShow: true,
          resizeLogo: true
        }
        prefetchItems = {
          header,
          aboutUs
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)

        this.$store.dispatch('clearPrefetchItems')
      }
    }
  }
}
</script>

<style scoped>

</style>

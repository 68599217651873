<template>
  <div v-click-outside-area="onClickOutsideDropDown">
    <div v-if="!isMobile" :class="{'active': isActive}"
         class="search__option">
      <input type="text" id="search_property-type" @click="onClickSelectOption" :placeholder="getPropertyDropdownPlaceHolder()" class="search__option--input" style="cursor: pointer" readonly>
      <div>
        <ul style="list-style: none">
          <li style="margin:8px 0"><label style="cursor: pointer; user-select:none;">
            <input type="checkbox" v-model="selectPropertyType.all" @click="onSelectedAllPropertyType" class="all-property" style="margin-right: 12px;">All Property Types</label></li>
          <li style="margin:8px 0"><label style="cursor: pointer; user-select:none;">
            <input type="checkbox" v-model="selectPropertyType.villa" @click="onSelectedPropertyType('villa')" class="property-type" style="margin-right: 12px;">Houses & Villas</label></li>
          <li style="margin:8px 0"><label style="cursor: pointer; user-select:none;">
            <input type="checkbox" v-model="selectPropertyType.condo" @click="onSelectedPropertyType('condo')" class="property-type" style="margin-right: 12px;">Condos & Apartments</label></li>
          <li style="margin:8px 0"><label style="cursor: pointer; user-select:none;">
            <input type="checkbox" v-model="selectPropertyType.land" @click="onSelectedPropertyType('land')" class="property-type" style="margin-right: 12px;">Lands</label></li>
          <li style="margin:8px 0"><label style="cursor: pointer; user-select:none;">
            <input type="checkbox" v-model="selectPropertyType.commercial" @click="onSelectedPropertyType('commercial')" class="property-type" style="margin-right: 12px;">Commercials</label></li>
        </ul>
      </div>
      <span style="display:block; position:absolute; top: 50%; right: 16px; transform: translateY(-50%); pointer-events:none; border-top:6px solid #cccccc; border-left:4px solid transparent; border-right:4px solid transparent"></span>
    </div>
    <div v-if="isMobile">
      <label>
        <input type="checkbox" v-model="selectPropertyType.all" @click="onSelectedAllPropertyType" class="all-property" style="margin-right: 12px;">All Property Types
      </label>
      <label>
        <input type="checkbox" v-model="selectPropertyType.villa" @click="onSelectedPropertyType('villa')" class="property-type" style="margin-right: 12px;">Houses & Villas
      </label>
      <label>
        <input type="checkbox" v-model="selectPropertyType.condo" @click="onSelectedPropertyType('condo')" class="property-type" style="margin-right: 12px;">Condos & Apartments
      </label>
      <label>
        <input type="checkbox" v-model="selectPropertyType.land" @click="onSelectedPropertyType('land')" class="property-type" style="margin-right: 12px;">Lands
      </label>
      <label>
        <input type="checkbox" v-model="selectPropertyType.commercial" @click="onSelectedPropertyType('commercial')" class="property-type" style="margin-right: 12px;">Commercials
      </label>
    </div>
  </div>
</template>

<script>
import { PROPERTY_TYPE, PROPERTY_TYPE_TITLE } from '@/configs/constants'
import { clickOutsideArea } from '@/directives/index'

export default {
  name: 'SelectPropertyTypeDropdownComponent',
  model: {
    prop: 'selectPropertyType',
    event: 'onSelectPropertyTypeListChange'
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    clickOutsideArea
  },
  watch: {
    async $route () {
      this.isActive = false
    }
  },
  data () {
    return {
      PROPERTY_TYPE,
      PROPERTY_TYPE_TITLE,
      isActive: false,
      selectPropertyType: {
        all: false,
        villa: false,
        condo: false,
        land: false,
        commercial: false
      }
    }
  },
  methods: {
    onClickSelectOption: function () {
      this.isActive = !this.isActive
    },
    getPropertyDropdownPlaceHolder: function () {
      let count = 0
      let placeHolderText = 'Select Property Type'

      for (let key in this.selectPropertyType) {
        if (this.selectPropertyType[key] === true) {
          placeHolderText = PROPERTY_TYPE_TITLE[key] || 'N/A'
          count++
        }
      }

      placeHolderText = (count > 1) ? `${count} Property Types` : placeHolderText

      if (this.selectPropertyType.all === true) {
        placeHolderText = 'All Property Types'
      }

      return placeHolderText
    },
    onSelectedAllPropertyType: function () {
      const isSelectedAll = !this.selectPropertyType.all

      this.selectPropertyType.villa = isSelectedAll
      this.selectPropertyType.condo = isSelectedAll
      this.selectPropertyType.land = isSelectedAll
      this.selectPropertyType.commercial = isSelectedAll

      this.onSelectPropertyTypeChange()
    },
    onSelectedPropertyType: function (key) {
      this.selectPropertyType[key] = !this.selectPropertyType[key]
      this.selectPropertyType.all = true

      for (let key in this.selectPropertyType) {
        if (this.selectPropertyType[key] === false) {
          this.selectPropertyType.all = false
          break
        }
      }

      this.onSelectPropertyTypeChange()
    },
    onSelectPropertyTypeChange: function () {
      this.$emit('onSelectPropertyTypeListChange', this.selectPropertyType)
    },
    onClickOutsideDropDown: function () {
      this.isActive = false
    }
  }
}
</script>

<style scoped>

</style>

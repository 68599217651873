<template>
  <main-template :header="header" :is-loading="isLoading">
    <div class="container-fluid container">
      <h1>Estate Management</h1>
      <div v-html="estateManagement"></div>
    </div>
  </main-template>
</template>

<script>
import { delay } from '@/helpers'
import { get } from 'lodash'

import MainTemplate from '@/templates/MainTemplate'
import ConfigApi from '@/api/config-api'
import { COVER_IMG_TYPE, SERVICE_KEY } from '@/configs/constants'

export default {
  name: 'EstateManagement',
  components: { MainTemplate },
  data () {
    return {
      isLoading: false,
      estateManagement: '',
      header: {
        className: '',
        style: '',
        imgPath: '',
        isShow: true
      }
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const promises = [
          ConfigApi.coverImgUrl(COVER_IMG_TYPE.ESTATE_MANAGEMENT),
          ConfigApi.configByKey(SERVICE_KEY.ESTATE_MANAGEMENT)
        ]

        const result = await Promise.all(promises)

        const coverImg = result[0]
        const estateManagement = get(result[1], 'configValue', '')

        const header = {
          className: '',
          style: '',
          imgPath: coverImg.url,
          isShow: true,
          resizeLogo: true
        }

        prefetchItems = {
          header,
          estateManagement
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)

        this.$store.dispatch('clearPrefetchItems')
      }
    }
  }
}
</script>

<style scoped>

</style>

import baseApi from './base-api'
import { PROPERTY_BOOKING_API_URL } from '../configs/service-url'

const coverImgUrl = async (coverImgType) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.CONFIG}/cover-img/${coverImgType}`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const configByKey = async (configKey) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.CONFIG}/${configKey}`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const companyLocation = async () => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.CONFIG}/company-location/`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const headerData = async () => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.CONFIG}/header-data/`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

const footerData = async () => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.CONFIG}/footer/`
    const result = await baseApi.get(url)

    return result
  } catch (err) {
    throw err
  }
}

export default {
  coverImgUrl,
  configByKey,
  companyLocation,
  headerData,
  footerData
}

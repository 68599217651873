export const STATUS = {
  DISABLE: 0,
  ENABLE: 1
}

export const LIST_TYPE = {
  BUY: 'buy',
  RENT: 'rent'
}

export const PROPERTY_TYPE = {
  VILLAS_AND_HOUSES: 'villa',
  APARTMENTS_AND_CONDOS: 'condo',
  LAND: 'land',
  COMMERCIAL: 'commercial'
}

export const PROPERTY_TYPE_TITLE = {
  villa: 'Villa & House',
  condo: 'Condo & Apartment',
  land: 'Lands',
  commercial: 'Commercials'
}

export const PROPERTY_STATUS = {
  UN_PUBLISH: 0,
  OFF_PLAN: 1,
  NEW_BUILDING: 2,
  RESALE: 3,
  NEW_LISTING: 4,
  NO_STATUS: 50
}

export const PROPERTY_STATUS_TEXT = {
  0: 'N/A',
  1: 'Off Plan',
  2: 'New Building',
  3: 'Resale',
  4: 'New Listing'
}

export const LOCATION = [
  {
    id: 'PKT-BANGTAO',
    title: 'Bangtao',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-CHERNG-TALAY',
    title: 'Cherng Talay',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-LAGUNA',
    title: 'Laguna',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-LAYAN',
    title: 'Layan',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-MAI-KHAO',
    title: 'Mai Khao',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-NAI-THON',
    title: 'Nai Thon',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-NAI-YANG',
    title: 'Nai Yang',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-NATAI',
    title: 'Natai',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-THAI-MUANG',
    title: 'Thai Muang',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-THALANG',
    title: 'Thalang',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'north', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-KALIM',
    title: 'Kalim',
    district: 'Kathu',
    province: 'Phuket',
    tag: [
      'west', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-KAMALA',
    title: 'Kamala',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'west', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-KARON',
    title: 'Karon',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'west', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-KATA',
    title: 'Kata',
    district: 'Chalong',
    province: 'Phuket',
    tag: [
      'west', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-PATONG',
    title: 'Patong',
    district: 'Kathu',
    province: 'Phuket',
    tag: [
      'west', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-SURIN',
    title: 'Surin',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'west', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-CAPE-PANWA',
    title: 'Cape Panwa',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'south', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-CHALONG',
    title: 'Chalong',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'south', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-NAI-HARN',
    title: 'Nai Harn',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'south', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-RAWAI',
    title: 'Rawai',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'south', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-AO-POR',
    title: 'Ao Por',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'east', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-CAPE-YAMU',
    title: 'Cape Yamu',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'east', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-KOH-KAEW',
    title: 'Koh Kaew',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'east', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-PAKLOK',
    title: 'Paklok',
    district: 'Talang',
    province: 'Phuket',
    tag: [
      'east', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-KATHU',
    title: 'Kathu',
    district: 'Kathu',
    province: 'Phuket',
    tag: [
      'town', 'seaview'
    ],
    isSelected: false
  },
  {
    id: 'PKT-TOWN',
    title: 'Phuket Town',
    district: 'Muang',
    province: 'Phuket',
    tag: [
      'town', 'seaview'
    ],
    isSelected: false
  }
]

export const API_KEY = {
  GOOGLE_MAP: 'AIzaSyDkDG4Y8A50GMLMiue_ulFIYqmxpDCfB-I'
}

export const PROPERTY_ATTRIBUTE_LABEL = {
  sleep: 'Bed',
  bath: 'Bath',
  view: 'View',
  buildingArea: 'Building Area',
  landArea: 'Land Area',
  livingArea: 'Living Area',
  totalUnit: 'Units',
  swimmingPool: 'Swimming Pool',
  floor: 'Floors',
  floorInBuilding: 'Floor in Building',
  furnish: 'Furnished',
  landTitle: 'Land Title',
  parking: 'Parking',
  ownership: 'Ownership',
  rentGuarantee: 'Rental Guarantee',
  developer: 'Developer'
}

export const PROPERTY_ATTRIBUTE_DETAIL_FEATURE_LABEL = {
  sleep: 'Bedroom',
  furnish: 'Furnished',
  bath: 'Bathroom',
  swimmingPool: 'Swimming Pool',
  landTitle: 'Land Title',
  view: 'View',
  landArea: 'Land Area',
  floorInBuilding: 'Storeys',
  buildingArea: 'Building Area',
  parking: 'Parking',
  livingArea: 'Living Area',
  petAllow: 'Pet Allowed',
  camFee: 'CAM Fee',
  ownership: 'Ownership',
  yearFinished: 'Year Finished',
  gateCommunity: 'Gate Community',
  rentGuarantee: 'Rental Guarantee'
}

export const PROPERTY_ATTRIBUTE_PROJECT_OVERVIEW_LABEL = {
  projectName: 'Project Name',
  projectArea: 'Project Area',
  developer: 'Developer',
  gym: 'Gym',
  construction: 'Construction',
  swimmingPoolProject: 'Swimming Pool',
  floor: 'Floor',
  cctv: 'CCTV',
  building: 'Building',
  security: 'Security',
  totalUnit: 'Units',
  parkingProject: 'Parking',
  yearFinishedProject: 'Year Finished',
  petAllowProject: 'Pet Allowed',
  yearBuilt: 'Year Built',
  managementCompany: 'Management Company'
}

export const RENT_TYPE = {
  PER_DAY: 'day',
  PER_MONTH: 'month',
  PER_YEAR: 'year'
}

export const RENT_TYPE_TITLE = {
  day: 'Day',
  month: 'Month',
  year: 'Year'
}

export const HEADER_STYLE = {
  FULL: 'full',
  HALF: 'half'
}

export const COVER_IMG_TYPE = {
  HOME: 'cover-home',
  ABOUT_US: 'cover-about-us',
  BUY: 'cover-buy',
  RENTAL: 'cover-rental',
  LISTING_PROPERTY: 'cover-listing-property',
  CONTACT_US: 'cover-contact-us',
  LEGAL_CONSULTANT: 'cover-legal-consultant',
  VILLA_MANAGEMENT: 'cover-villa-management',
  ESTATE_MANAGEMENT: 'cover-estate-management',
  PRIVACY_POLICY: 'cover-privacy-policy',
  TERM_AND_CONDITION: 'cover-term-and-condition'
}

export const SERVICE_KEY = {
  LEGAL_CONSULTANT: 'legalConsultant',
  VILLA_MANAGEMENT: 'villaManagement',
  ESTATE_MANAGEMENT: 'estateManagement',
  PRIVACY_POLICY: 'privacyPolicy',
  TERM_AND_CONDITION: 'termAndCondition',
  ABOUT_US: 'aboutUs',
  CONTACT_US: 'contactUs',
  HOME: 'home',
  LIST_PROPERTY: 'listProperty'
}

export const RECAPTCHA_SITE_KEY = '6Ld9OnwaAAAAAKFbGvkAANkLfrraO8mRpBHYFau-'

import { get } from 'lodash'

const defaultImg = require('@/assets/img/default-property-img.png')

export const numberFormat = (value, suffix = '') => {
  if (!value) {
    return 0
  }

  let intValue = Number(value).toFixed(0)
  let result = intValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  let valueArr = value.toString().split('.')

  if (valueArr.length === 2) {
    let decimal = valueArr[1]
    if (decimal.length === 1) {
      decimal = `${decimal}0`
    }

    result = `${result}.${decimal}`
  }

  return `${result}${suffix}`
}

export const delay = (millisec) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true)
    }, millisec)
  })
}

export const getImageUrl = (propertyPhotoList) => {
  const propertyPhoto = propertyPhotoList.find(item => item.type === 'property')
  const photo = get(propertyPhoto, 'url', defaultImg)

  return photo
}

import baseApi from './base-api'
import { PROPERTY_BOOKING_API_URL } from '../configs/service-url'

const sendInquiry = async (data) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.SEND_EMAIL}/inquiry/`
    const result = await baseApi.post(url, data)

    return result
  } catch (err) {
    throw err
  }
}

const sendContact = async (data) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.SEND_EMAIL}/contact/`
    const result = await baseApi.post(url, data)

    return result
  } catch (err) {
    throw err
  }
}

const sendListProperty = async (data) => {
  try {
    const url = `${PROPERTY_BOOKING_API_URL.SEND_EMAIL}/list-property/`
    const result = await baseApi.post(url, data)

    return result
  } catch (err) {
    throw err
  }
}

export default {
  sendInquiry,
  sendContact,
  sendListProperty
}

import fetch from 'node-fetch'
import LocalStorageFactory from '@/factories/local-storage-factory'
import PubSub from '@/pubsub/index'
const HTTP_STATUS_NOT_FOUND = 404
const defaultHeader = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const get = async (url, headers = {}, isRequireToken = true) => {
  try {
    const res = await _fetchData('GET', url, undefined, headers, isRequireToken)

    return res
  } catch (err) {
    throw err
  }
}

const post = async (url, data, headers = {}, isRequireToken = true) => {
  try {
    const res = await _fetchData('POST', url, data, headers, isRequireToken)

    return res
  } catch (err) {
    throw err
  }
}

const put = async (url, data, headers = {}, isRequireToken = true) => {
  try {
    const res = await _fetchData('PUT', url, data, headers, isRequireToken)

    return res
  } catch (err) {
    throw err
  }
}

const remove = async (url, headers = {}, isRequireToken = true) => {
  try {
    const res = await _fetchData('DELETE', url, undefined, headers, isRequireToken)

    return res
  } catch (err) {
    throw err
  }
}

const _fetchData = async (method, url, data, headers = {}, isRequireToken = true) => {
  const request = {
    method: method
  }

  const token = LocalStorageFactory.getAccessToken()
  const authorizationHeader = (isRequireToken) ? ({ 'Authorization': token }) : {}

  const headersData = Object.assign({}, defaultHeader, headers, authorizationHeader)
  request.headers = headersData
  request.body = (data) ? JSON.stringify(data) : undefined

  const res = await fetch(url, request)
  await _validateHttpStatus(res)

  const result = await res.json()
  return result
}

async function _validateHttpStatus (res) {
  const regExp = /^[2][0-9][0-9]/
  const isMatch = regExp.test(res.status)

  if (res.status === HTTP_STATUS_NOT_FOUND) {
    PubSub.publish(PubSub.EVENT.NOT_FOUND)
  } else if (!isMatch) {
    const result = await res.json()
    throw new Error(result.error)
  }

  return true
}

export default {
  get,
  post,
  put,
  remove
}

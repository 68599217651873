import Vue from 'vue'
import App from '@/App.vue'
// import router from '@/router'
import { createRouter } from '@/router'
import { createStore } from '@/store'
import { sync } from 'vuex-router-sync'
import Skeleton from 'vue-loading-skeleton'
import PubSub from '@/pubsub/index'
import Cookies from 'vue-cookies'
import LightGallery from 'vue-light-gallery'

Vue.use(LightGallery)
Vue.use(Cookies)
Vue.use(Skeleton)
Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

export function createApp () {
  const router = createRouter()
  const store = createStore()

  sync(store, router)

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  const pageNotFound = function () {
    return router.push({
      name: 'PageNotFound'
    })
  }

  PubSub.subscribe(PubSub.EVENT.NOT_FOUND, pageNotFound)

  return { app, router, store }
}

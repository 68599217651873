<template>
  <main-template :header="header" :is-loading="isLoading">
    <div class="container-fluid container">
      <h1>List Property</h1>
      <div class="row">
        <div class="col-lg-8">
          <form style="background-color:#EEEEEE; padding:24px;">
            <section style="margin-bottom: 2rem">
              <h2 style="margin-bottom: 1em;">Your Details</h2>
              <div class="row">
                <div class="col-md-6">
                  <label>First Name</label>
                  <input type="text" v-model="listProperty.firstName">
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <input type="text" v-model="listProperty.lastName">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Email</label>
                  <input type="text" v-model="listProperty.email">
                </div>
                <div class="col-md-6">
                  <label>Phone</label>
                  <input type="text" v-model="listProperty.phone">
                </div>
              </div>
            </section>
            <section>
              <h2 style="margin-bottom: 1em;">Property Details</h2>
              <div class="row">
                <div class="col-md-6">
                  <label>Property Type</label>
                  <div>
                    <select v-model="listProperty.propertyType">
                      <option :value="PROPERTY_TYPE.VILLAS_AND_HOUSES">Houses & Villas</option>
                      <option :value="PROPERTY_TYPE.APARTMENTS_AND_CONDOS">Condos & Apartments</option>
                      <option :value="PROPERTY_TYPE.LAND">Lands</option>
                      <option :value="PROPERTY_TYPE.COMMERCIAL">Commercials</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Listing Type</label>
                  <div>
                    <label>
                      <input type="checkbox" v-model="listProperty.isSale" :value="1"> Buy
                    </label>
                    <label style="margin-left: 24px;">
                      <input type="checkbox" v-model="listProperty.isRent" :value="1"> Rent
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Sale Price (THB)</label>
                  <small style="color: #888888; display: block">If you are not sure, we provide a free valuation</small>
                  <div>
                    <input type="text" v-model="listProperty.salePrice">
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Rent Price (THB)</label>
                  <small style="color: #888888; display: block">If you are not sure, we provide a free valuation</small>
                  <div>
                    <input type="text" v-model="listProperty.rentPrice">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>Location</label>
                  <div>
                    <select v-model="listProperty.location">
                      <option value=""> - </option>
                      <option v-for="(item, index) of LOCATION" :key="index"
                              :value="item.title">
                        {{item.title}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <label>Bedrooms</label>
                  <div>
                    <input type="text" v-model="listProperty.bedrooms">
                  </div>
                </div>
                <div class="col-lg-6">
                  <label>Bathrooms</label>
                  <div>
                    <input type="text" v-model="listProperty.bathrooms">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>Furnished</label>
                  <div>
                    <input type="text" v-model="listProperty.furnished">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Land Size (sqm)</label>
                  <div>
                    <input type="text" v-model="listProperty.landSize">
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Internal Size (sqm)</label>
                  <div>
                    <input type="text" v-model="listProperty.internalSize">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>Property Photo</label>
                  <small style="color: #888888; display: block">This Helps to process your listing inquiry quicker Max, file size is 10 MB</small>
                  <div>
                    <input type="file" id="property-photo" accept="image/*" @change="getBase64()" multiple>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>Message</label>
                  <div>
                    <textarea v-model="listProperty.message"></textarea>
                  </div>
                </div>
              </div>
            </section>
            <div style="padding-bottom: 10px">
              <vue-recaptcha :sitekey="RECAPTCHA_SITE_KEY"
                             @verify="verifyRecaptcha($event)"
                             @expired="recaptchaExpired">
              </vue-recaptcha>
            </div>
            <button type="button" class="btn"
                    :class="{'btn-disabled': !isVerifyRecaptcha}"
                    :disabled="!isVerifyRecaptcha"
                    @click="onSubmitProperty">Submit
            </button>
          </form>
        </div>
        <div class="col-lg-4" v-html="listPropertyContent"></div>
      </div>
    </div>
  </main-template>
</template>

<script>
import { get } from 'lodash'
import { delay } from '@/helpers'
import {
  COVER_IMG_TYPE,
  PROPERTY_TYPE,
  LOCATION,
  SERVICE_KEY
} from '@/configs/constants'

import ConfigApi from '@/api/config-api'
import EmailApi from '@/api/email-api'
import MainTemplate from '@/templates/MainTemplate'
import VueRecaptcha from 'vue-recaptcha'
import AlertMixin from '@/mixins/alert-mixin'

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''
const TOTAL_UPLOAD_SIZE = 5767168

export default {
  name: 'ListProperty',
  mixins: [AlertMixin],
  components: { MainTemplate, VueRecaptcha },
  data () {
    return {
      isLoading: false,
      LOCATION,
      PROPERTY_TYPE,
      RECAPTCHA_SITE_KEY,
      isVerifyRecaptcha: false,
      fileSize: 0,
      header: {
        className: '',
        style: '',
        imgPath: '',
        isShow: true
      },
      listPropertyContent: '',
      listProperty: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSale: 0,
        isRent: 0,
        propertyType: PROPERTY_TYPE.VILLAS_AND_HOUSES,
        salePrice: '',
        rentPrice: '',
        location: '',
        bedrooms: '',
        bathrooms: '',
        furnished: '',
        landSize: '',
        internalSize: '',
        message: '',
        photoList: []
      }
    }
  },
  computed: {
    prefetchItems () {
      return this.$store.state.prefetchItems
    }
  },
  async serverPrefetch () {
    await this.preparePrefetchItems(true)
  },
  async created () {
    try {
      this.isLoading = true
      await this.preparePrefetchItems(false)
      await delay(300)
      this.isLoading = false
    } catch (err) {
      this.alertMessageError(err.message)
    }
  },
  methods: {
    async preparePrefetchItems (isServerPrefetch) {
      let prefetchItems = this.prefetchItems
      if (prefetchItems === null) {
        const promises = [
          ConfigApi.coverImgUrl(COVER_IMG_TYPE.LISTING_PROPERTY),
          ConfigApi.configByKey(SERVICE_KEY.LIST_PROPERTY)
        ]

        const result = await Promise.all(promises)

        const coverImg = result[0]
        const listPropertyConfig = result[1]

        const listPropertyContent = get(listPropertyConfig, 'configValue', '')

        const header = {
          className: '',
          style: '',
          imgPath: coverImg.url,
          isShow: true,
          resizeLogo: true
        }
        prefetchItems = {
          header,
          listPropertyContent
        }
      }

      if (isServerPrefetch) {
        this.$store.dispatch('initPrefetchItems', prefetchItems)
      } else {
        Object.assign(this, prefetchItems)

        this.$store.dispatch('clearPrefetchItems')
      }
    },
    verifyRecaptcha: function (response) {
      this.isVerifyRecaptcha = true
    },
    recaptchaExpired: function () {
      this.isVerifyRecaptcha = false
    },
    onSubmitProperty: async function () {
      this.isLoading = true

      try {
        await EmailApi.sendListProperty(this.listProperty)
      } catch (err) {
        this.alertMessageError(err.message)
      }

      this.listProperty = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSale: 0,
        isRent: 0,
        propertyType: PROPERTY_TYPE.VILLAS_AND_HOUSES,
        salePrice: '',
        rentPrice: '',
        location: '',
        bedrooms: '',
        bathrooms: '',
        furnished: '',
        landSize: '',
        internalSize: '',
        message: '',
        photoList: []
      }
      this.fileSize = 0

      await delay(300)
      this.isLoading = false
    },
    getBase64: function () {
      this.listProperty.photoList = []
      const input = event.target

      if (input.files[0]) {
        for (let file of input.files) {
          this.fileSize += file.size

          if (this.fileSize > TOTAL_UPLOAD_SIZE) {
            input.value = ''
            this.fileSize -= file.size

            return false
          }

          const reader = new FileReader()
          reader.onload = (e) => {
            const fileData = {
              fileName: file.name,
              type: 'list-property',
              content: e.target.result
            }

            this.listProperty.photoList.push(fileData)
          }

          reader.readAsDataURL(file)
        }
      }
    }
  }
}
</script>

<style scoped>
  .btn-disabled {
    opacity: 0.5;
  }

  .row {
    margin-bottom: 10px;
  }
</style>

const List = () => import(/* webpackChunkName: "property" */ './List.vue')
const Detail = () => import(/* webpackChunkName: "property" */ './Detail.vue')
const SearchProperty = () => import(/* webpackChunkName: "property" */ './SearchProperty.vue')

export default [
  {
    path: '',
    name: 'PropertyList',
    component: List
  },
  {
    path: 'search',
    name: 'searchProperty',
    props: true,
    component: SearchProperty
  },
  {
    path: 'detail/:propertyId',
    name: 'PropertyDetail',
    component: Detail
  },
  {
    path: ':listType',
    name: 'AllPropertyListByType',
    props: true,
    component: List
  },
  {
    path: ':listType/:propertyType',
    name: 'PropertyListByType',
    props: true,
    component: List
  }
]
